@font-face {
  font-family: "Anja-Eliane";
  src: url("./assets/static/fonts/Anja-Eliane.ttf");
  font-display: swap;
}

@font-face {
  font-family: "Real-Madrid-1";
  src: url("./assets/static/fonts//real-madrid-19-20.ttf");
  font-display: swap;
}

@font-face {
  font-family: "FC-Barocelona";
  src: url("./assets/static/fonts/barselona.ttf");
  font-display: swap;
}

@font-face {
  font-family: "Nunito-VariableFont";
  src: url("./assets/static/fonts/Nunito-VariableFont.ttf");
  font-display: swap;
}

@font-face {
  font-family: "PoetsenOne-Regular";
  src: url("./assets/static/fonts/PoetsenOne-Regular.ttf");
  font-display: swap;
}

:root {
  --cf-color-aux-1: #f8fafc;
  --cf-color-cpt-1: #94a3b3;
  --cf-color-bdr-1: #1e293b;
  --cf-color-bg-1: #0b141b;
  --cf-color-bg-hover-1: #00000044;

  --cf-font-sz-cpt-1: 1rem;
  --cf-font-sz-aux-1: calc(var(--cf-font-sz-cpt-1) * 15 / 8);
  --cf-font-sz-title-1: calc(var(--cf-font-sz-cpt-1) * 8);
  --cf-font-sz-btn-cpt-1: calc(var(--cf-font-sz-cpt-1) * 2);
  --cf-font-sz-nav-1: calc(var(--cf-font-sz-cpt-1) * 9 / 8);

  --cf-color-aux: var(--cf-color-aux-1);
  --cf-color-cpt: var(--cf-color-cpt-1);
  --cf-color-bdr: var(--cf-color-bdr-1);
  --cf-color-bg: var(--cf-color-bg-1);
  --cf-color-bg-hover: var(--cf-color-bg-hover-1);

  --cf-font-aux: var(--cf-font-aux-1);

  --cf-font-sz-cpt: var(--cf-font-sz-cpt-1);
  --cf-font-sz-aux: var(--cf-font-sz-aux-1);
  --cf-font-sz-title: var(--cf-font-sz-title-1);
  --cf-font-sz-btn-cpt: var(--cf-font-sz-btn-cpt-1);
  --cf-font-sz-nav: var(--cf-font-sz-nav-1);
}

body {
  padding: 0;
  box-sizing: border-box;
  margin: 0;
  background-color: #111111;
  min-height: 100vh;
}

#root {
  min-height: 100vh;
}
.imageglow {
  top: calc(50% - 30px);
  left: calc(50% - 30px);
  z-index: -1;
  border-radius: 50%;
  background-color: #fff;
  box-shadow: #d5d5d5 0 0 50px 64px inset, 0 0 20px 10px #ffffff,
    0 0 30px 15px #ffffff, 0 0 60px 30px #ffffffba;
}

.imageglowing {
  top: calc(50% - 30px);
  left: calc(50% - 30px);
  z-index: -1;
  border-radius: 20%;
  background-color: #fff;
  box-shadow: 0 0 5px 2px #fca311, 0 0 10px 5px #a46a0c, 0 0 15px 10px #b29100;
}

.cf-main-sec {
  background: none;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.cf-new-container {
  max-width: 1280px;
  margin: 0 auto;
  padding: 0 1rem;
}

.cf-myFlex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.cf-header-sp {
  padding: 0.5rem 0;
}

.img-badge {
  display: flex;
  align-items: center;
  text-align: center;
  color: white;
  font-family: "Nunito-VariableFont";
}

.img-badge img {
  margin-right: 10px;
}

/* Mobile Menus  */
header .cf-menus-btn {
  display: none;
}
/* Header end  */

/* HOme Page  */

/* Hero Section  */
.hero-bg {
  /* background: linear-gradient(180deg, #5d0000, 5%, #5e0000, 50%, #4a0000); */
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  overflow: auto;
  height: 100vh;
}

.img-background {
  position: absolute;
  top: 0px;
  width: 100vw;
  height: 100vh;
  object-fit: cover;
  filter: blur(5px);
  z-index: -1;
}

.cf-hero-sec {
  display: flex;
  gap: 2rem;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 3rem 0 3rem 0;
  min-height: calc(100vh - 245px);
}

.cf-hero-sec img.img-hero {
  max-width: 300px;
  z-index: 1;
}

.click-to-wal-con {
  position: relative;
}

img.img-wallet-connect {
  cursor: pointer;
  transition-duration: 0.15s;
  width: 200px;
}

img.img-wallet-connect:hover {
  scale: 1.1;
}

img.img-wallet-connect:active {
  scale: 0.9;
}

img.img-click-here {
  position: absolute;
  width: 200px;
  left: -240px;
  bottom: 10px;
}

/* For mobile */

.paymastertext {
  color: #ffffff;
}

.pool-container {
  width: 100%;
  z-index: 10;
}

.keep-in-pool {
  width: fit-content;
  background: #6e717394;
  padding: 10px 20px;
  color: #ffffff;
  font-size: 20px;
  border: solid 2px #ffffff;
  border-radius: 10px;
  font-family: "Anja-Eliane";
  min-width: 200px;
}

.leaderboard {
  width: fit-content;
  background: #6e717394;
  padding: 10px 20px;
  color: #ffffff;
  font-size: 20px;
  border: solid 2px #ffffff;
  border-radius: 10px;
  font-family: "PoetsenOne-Regular";
  max-width: 800px;
  z-index: 10;
}

.tb-leaderboard {
  text-align: center;
}

.tb-leaderboard .row-player {
  width: 160px;
}

.board-switch {
  width: fit-content;
  border-radius: 10px;
  max-width: 800px;
  width: 400px;
  z-index: 10;
  display: flex;
  background: #6e717394;
  border: solid 2px #ffffff;
  border-radius: 10px;
}

.board-switch button.board-option {
  color: #ffffff;
  font-size: 20px;
  width: 50%;
  font-family: "PoetsenOne-Regular";
  border: none;
  background: none;
  border-radius: 6px;
  cursor: pointer;
  padding: 10px;
}

.board-switch button.board-option.selected {
  color: #000;
  background: #ffffff;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.flip-content {
  margin: 32px 0rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 3px;
  font-family: "Anja-Eliane";
  max-width: 900px;
  width: 600px;
}

.flip-content button {
  background: none;
  border: none;
  padding: 0px;
  transition-duration: 0.3s;
  cursor: pointer;
  font-family: "Anja-Eliane";
  min-width: 110px;
  z-index: 10;
}

.flip-content .heads-tails button {
  font-size: 2.25rem;
}

.flip-content .usd-grid button {
  font-size: 1.5rem;
}

.flip-content .faucet button {
  font-size: 1.5rem;
}

.flip-content p {
  margin-top: 20px;
  color: white;
  font-size: 1.75rem;
  text-align: center;
}

.flip-content .heads-tails {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  gap: 20px;
  align-items: center;
  justify-content: space-around;
}

.btn-content img {
  -webkit-filter: drop-shadow(1px 1px 0 white) drop-shadow(-1px -1px 0 white);
  filter: drop-shadow(1px 1px 0 white) drop-shadow(-1px -1px 0 white);
}

.flip-content .heads-tails .btn-content.button-cr {
  font-family: "Real-Madrid-1";
  padding: 8px 16px;
}

.flip-content .heads-tails {
  margin-top: 20px;
}

.flip-content .heads-tails .button-lm.btn-content {
  font-family: "FC-Barocelona";
}

.flip-content .heads-tails button {
  position: relative;
  width: 260px;
}

.flip-content .heads-tails button:active {
  scale: 0.9;
}

.flip-content .heads-tails button .btn-content {
  position: relative;
  border-radius: 1rem;
  padding-top: 0.5rem;
  border: solid 5px #f9e181;
  background: #ffffff8c;
  color: black;
  font-size: 2.25rem;
  line-height: 1;
  padding: 10px 16px;
  z-index: 1;
  transition-duration: 0.15s;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.flip-content .heads-tails button.pressed .btn-content {
  background: #bb9030cf;
  scale: 95%;
}

.fire img {
  -webkit-filter: drop-shadow(3px -4px 6px #fff36c);
  filter: drop-shadow(3px -4px 6px #fff36c);
}

.button-cr img.celebration {
  height: 45px;
}

.button-lm img.celebration {
  height: 40px;
}

img.coin-on-btn {
  height: 35px;
}

.flip-content .usd-grid {
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(3, minmax(0, 1fr));
}

.flip-content .usd-grid button,
.flip-content .faucet button {
  position: relative;
  width: 8rem;
  max-width: 100%;
  animation-duration: 0.3s;
}

.flip-content .usd-grid button:active,
.flip-content .faucet button:active {
  scale: 80%;
}

.flip-content .usd-grid button.pressed:active,
.flip-content .faucet button.pressed:active {
  scale: 80%;
}

.flip-content .usd-grid button .btn-content,
.flip-content .faucet button .btn-content {
  position: relative;
  font-size: 22px;
  padding: 0.5rem 0;
  transition-duration: 0.15s;
  transition-property: all;
  color: black;
  padding-bottom: 0.5rem;
  padding-top: 0.5rem;
  background-color: #efae19;
  border: solid 2px #f9e181;
  border-radius: 1rem;
  width: 100%;
  line-height: 30px;
  z-index: 10;
}

.flip-content .usd-grid button:hover .btn-content,
.flip-content .faucet button:hover .btn-content {
  background: #b5740c;
}

.flip-content .usd-grid button.pressed .btn-content,
.flip-content .faucet button.pressed .btn-content {
  background: #b5740c;
}

.flip-content .usd-grid button.pressed,
.flip-content .faucet button.pressed {
  scale: 90%;
}

.flip-content .usd-grid button .shadow,
.flip-content .faucet button .shadow {
  position: absolute;
  width: 100%;
  border-radius: 1rem;
  height: 100%;
  background: #986f14;
  border: solid 2px #f9e181;
  height: 3rem;
  position: absolute;
  display: block;
  z-index: 0;
  top: 5px;
}

.flip-content .usd-grid button.pressed .shadow,
.flip-content .faucet button.pressed .shadow {
  scale: 0.9;
  visibility: hidden;
}

header {
  background-color: #020a124f;
}

.hide {
  display: none !important;
}

.main-sec-img {
  display: flex;
  align-items: baseline;
  gap: 40px;
}

.main-sec-img .img-coin {
  width: 250px;
  padding-bottom: 30px;
}

.div-coin {
  display: flex;
  z-index: 15;
  position: relative;
  -webkit-animation: flip-heads 3s infinite both;
  animation: flip-heads 3s infinite both;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
}

@keyframes flip-heads {
  0% {
    -webkit-transform: scale(1) rotateY(0);
    transform: scale(0.9) rotateY(0);
  }
  50% {
    -webkit-transform: scale(1.1) rotateY(900deg);
    transform: scale(1.1) rotateY(900deg) translateY(-30px);
  }
  100% {
    -webkit-transform: scale(1) rotateY(1800deg);
    transform: scale(0.9) rotateY(1800deg);
  }
}

.main-sec-img .img-coin.img-coin-cr {
  backface-visibility: hidden;
  position: relative;
  z-index: 8;
}

.main-sec-img .img-coin.img-coin-lm {
  transform: rotate3d(0, 1, 0, 180deg);
  backface-visibility: hidden;
  position: absolute;
  left: 0px;
  z-index: 10;
}

.main-sec-img .img-cr {
  height: 480px;
}

img.img-glow {
  scale: 1.2;
  z-index: 10;
  -webkit-filter: drop-shadow(0px -4px 6px #fff36c);
  filter: drop-shadow(0px -4px 6px #fff36c);
}

.main-sec-img .img-lm {
  height: 480px;
  margin-left: 0px;
  padding-right: 0px;
  /* padding-bottom: 40px; */
}

.ns-newlogo {
  height: 40px;
}

img.btn-fud {
  margin-top: 60px;
  margin-bottom: 30px;
  width: 180px;
  cursor: pointer;
  transition-duration: 0.15s;
}

.star {
  position: absolute;
  top: -20px;
  color: #fca311;
  animation: animate 5s linear forwards;
  width: 30px;
  height: 30px;
  /* box-shadow: #eca82d 0 0 15px 15px inset, 0 0 10px 5px #fab800; */
  border-radius: 50%;
  opacity: 80%;
}

/* .star::before {
  content: "\f042";
  font-family: fontAwesome;
  background: url("../src/assets/static/imgs/coin_gold_ronaldo.png");
  text-shadow: 0 0 5px #fca311, 0 0 30px #fca311, 0 0 50px #fca311;
} */

img.btn-fud:hover {
  scale: 1.1;
}

img.btn-fud:active {
  scale: 0.9;
}

.status-bg {
  background-repeat: no-repeat !important;
  background-size: contain !important;
  width: 240px;
  margin-bottom: 30px;
  text-align: center;
  padding: 10px 20px;
  border-radius: 10px;
  border: solid 2px #ffffff;
  z-index: 10;
}

.status-bg.win-box {
  background-color: #f8cb00;
  z-index: 1;
}

.lb-1st-line {
  font-size: 1.75rem;
  margin: 0;
}

.lb-2nd-line {
  font-size: 40px !important;
  line-height: 2rem;
  margin-top: 0.75rem !important;
  margin-bottom: 1.5rem;
}

.lb-win-lose {
  font-size: 38px !important;
  line-height: 2rem;
  margin: 0;
}

.lb-sm-bets {
  margin-top: 1.5rem !important;
  font-size: 1.25rem !important;
}

.mt-11 {
  margin-top: 2.75rem;
}

.mt-15 {
  margin-top: 3.25rem;
}

/* Define the animation named "flicker" */
@keyframes flicker {
  /* Initial state of animation */
  0%, 
    /* Final state of animation */
    100% {
    text-shadow: 2px -0.5px 3px #fff,
      /* Innermost layer - intense heat (white) */ 3px -1px 2px #ff3,
      /* Second layer - core of flame (yellow) */ 5px -3px 8px #f90,
      /* Middle layer - body of flame (orange) */ 8px -6px 10px #c33; /* Outermost layer - edges of flame (red) */
  }
  /* Middle state of animation */
  50% {
    text-shadow: 4px -2px 6px #fff,
      /* Innermost layer - intense heat (white) */ 6px -5px 4px #ff3,
      /* Second layer - core of flame (yellow) */ 10px -12px 12px #f90,
      /* Middle layer - body of flame (orange) */ 14px -20px 16px #c33; /* Outermost layer - edges of flame (red) */
  }
}

.fire {
  /* Apply the "flicker" animation to the .fire class */
  animation: flicker 2s infinite;
}

button.btn-try-again {
  height: 4rem;
  width: 200px;
  border-radius: 0.75rem;
  transition-duration: 0.15s;
  color: white;
  font-size: 1.5rem;
}

button.btn-try-again:active {
  translate: 0px 5px;
  box-shadow: 0px 5px #b3173f;
}

button.btn-try-again.win {
  background: hsl(108, 94%, 41%); /*#419132;*/
  box-shadow: 0px 10px hsl(116, 97%, 24%); /*#317424;*/
}

button.btn-try-again.win:active {
  box-shadow: 0px 5px #317424;
}

button.btn-try-again:hover {
  filter: brightness(110%);
  -webkit-filter: brightness(110%);
}

button.btn-try-again.lose {
  background: #ea194e;
  box-shadow: 0px 10px #b3173f;
}

button.btn-try-again.lose:active {
  box-shadow: 0px 5px #b3173f;
}

.modal-container {
  position: absolute;
  top: 0px;
  width: 100vw;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
}

.modal-bg {
  position: absolute;
  top: 0px;
  width: 100vw;
  min-height: 100vh;
  background: black;
  opacity: 0.7;
}

img.img-flipping {
  width: 80%;
  max-width: 800px;
  margin: 50px;
  z-index: 10;
  border-radius: 20px;
}

.heads-tails-text {
  font-size: 4rem;
  font-family: "Anja-Eliane";
  color: white;
  text-align: center;
}

/* End Hero Section  */

footer {
  background-color: var(--cf-color-bg-hover);
  color: var(--cf-color-aux);
}

.cf-footer-myFlex {
  display: flex;
  justify-content: space-between;
  padding: 2rem 0;
}

.footer-bg {
  /* background: url("../src/assets/static/imgs/footer-bg.png"); */
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
}

footer .cf-copy-right {
  border-top: 1px solid var(--cf-color-bdr);
  border-bottom: 1px solid var(--cf-color-bdr);
  background-color: var(--cf-color-bg);
  font-size: 1.5rem;
  margin: 0;
  text-align: center;
  font-family: "Anja-Eliane";
}

/* Footer End  */

/* Media Queries  */

@media (max-width: 1100px) {
  .cf-hidden-on-desk {
    display: block;
  }

  /* Header Queries  */
  .cf-header-sp ul {
    display: none;
    background-color: #000000e0;
  }

  .cf-header-btn {
    display: none;
  }
  header .cf-menus-btn {
    background-color: transparent;
    outline: none;
    border: none;
    display: block;
    cursor: pointer;
  }
  /* ENd Header Queries  */
}

@media (max-width: 1080px) {
  /* Home Page Queries  */
  .cf-liear-bg img {
    max-width: 36px;
  }

  .flip-content {
    width: 500px;
  }
  .main-sec-img {
    gap: 5px;
  }

  .main-sec-img .img-cr {
    height: 400px;
    margin-right: -40px;
  }

  .main-sec-img .img-coin {
    width: 180px;
    padding-bottom: 25px;
  }

  .main-sec-img .img-lm {
    height: 400px;
    margin-left: -20px;
    margin-right: -30px;
  }
  /* Home Page Queries End  */

  /* Footer Media Queries */
  .cf-footer-myFlex {
    flex-wrap: wrap;
    gap: 1.5rem;
    align-items: center;
  }
  /* End Footer Media Queries */
}

@media (max-width: 580px) {
  .cf-hero-sec {
    flex-direction: column;
  }
}

@media (max-width: 540px) {
  .cf-stak-details-container {
    grid-template-columns: 1fr auto;
  }

  .main-sec-img {
    gap: 8px;
  }

  .flip-content {
    width: 100%;
    margin-top: 0px;
  }

  .col-time {
    font-size: 14px;
  }

  .flip-content .heads-tails {
    margin-top: 0px;
  }
  .main-sec-img .img-cr {
    height: 220px;
    margin-right: -30px;
  }

  .main-sec-img .img-coin {
    width: 110px;
    padding-bottom: 15px;
  }

  .main-sec-img .img-lm {
    height: 220px;
    margin-left: -15px;
    margin-right: -20px;
  }

  /* Footer Media Queries */
  .cf-footer-myFlex {
    flex-wrap: wrap;
    gap: 1.5rem;
    align-items: center;
    justify-content: center;
    padding: 2rem 0;
  }

  footer .cf-footer-links {
    align-items: center;
    justify-content: center;
    gap: 1.25rem;
  }
  /* End Footer Media Queries */
}

@media (max-width: 480px) {
  /* Home Page  */
  .cf-liear-bg {
    min-height: 70px;
  }

  .board-switch {
    width: 100%;
  }

  .col-time {
    font-size: 12px;
  }

  .leaderboard {
    font-size: 16px;
  }
  /* Home Page  */
}
.textShineBlack,
.textShineBlack div,
.textShineBlack p {
  background: linear-gradient(
    to right,
    #f6ee09 20%,
    #d2970c 30%,
    #ffffff 70%,
    #f8ba29 80%
  );
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  text-fill-color: transparent;
  background-size: 200% auto;
  animation: textShine 5s ease-in-out infinite alternate;
}

@keyframes textShine {
  to {
    background-position: 200%;
  }
}
.textShineBlackbottom {
  background: linear-gradient(
    to right,
    #60da0e 20%,
    #6ebd07 30%,
    #70b508 70%,
    #91f829 80%
  );
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  text-fill-color: transparent;
  background-size: 200% auto;
  animation: textShine 2s ease-in-out infinite alternate;
}

.button-82-pushable {
  margin-top: 60px;
  margin-bottom: 30px;
  position: relative;
  border: none;
  background: transparent;
  padding: 0;
  cursor: pointer;
  outline-offset: 4px;
  transition: filter 250ms;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  z-index: 10;
}

.button-82-shadow {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 12px;
  background: hsl(0deg 0% 0% / 0.25);
  will-change: transform;
  transform: translateY(2px);
  transition: transform 600ms cubic-bezier(0.3, 0.7, 0.4, 1);
}

.button-82-edge {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 12px;
  background: linear-gradient(
    to left,
    hsl(117, 96%, 22%) 0%,
    hsl(117, 95%, 29%) 8%,
    hsl(104, 95%, 29%) 92%,
    hsl(116, 97%, 24%) 100%
  );
}

.button-82-front {
  display: block;
  position: relative;
  padding: 12px 27px;
  border-radius: 12px;
  font-size: 1.1rem;
  color: white;
  background: hsl(108, 94%, 41%);
  will-change: transform;
  transform: translateY(-4px);
  transition: transform 600ms cubic-bezier(0.3, 0.7, 0.4, 1);
}

@media (min-width: 768px) {
  .button-82-front {
    font-size: 1.25rem;
    padding: 12px 42px;
  }
}

.button-82-pushable:hover {
  filter: brightness(110%);
  -webkit-filter: brightness(110%);
}

.button-82-pushable:hover .button-82-front {
  transform: translateY(-6px);
  transition: transform 250ms cubic-bezier(0.3, 0.7, 0.4, 1.5);
}

.button-82-pushable:active .button-82-front {
  transform: translateY(-2px);
  transition: transform 34ms;
}

.button-82-pushable:hover .button-82-shadow {
  transform: translateY(4px);
  transition: transform 250ms cubic-bezier(0.3, 0.7, 0.4, 1.5);
}

.button-82-pushable:active .button-82-shadow {
  transform: translateY(1px);
  transition: transform 34ms;
}

.button-82-pushable:focus:not(:focus-visible) {
  outline: none;
}
.button-33 {
  background-color: #ffffff;
  border-radius: 100px;
  box-shadow: rgb(237 237 237 / 33%) 0 -25px 18px -14px inset,
    rgba(219, 205, 5, 0.2) 0 1px 2px, rgb(231 231 231 / 33%) 0 2px 4px,
    rgb(241 241 241 / 20%) 0 4px 8px, rgb(217 217 217 / 40%) 0 8px 16px,
    rgb(243 243 243 / 45%) 0 16px 32px;
  color: #b97914d1;
  cursor: pointer;
  display: inline-block;
  font-family: "Anja-Eliane";
  padding: 7px 20px;
  text-align: center;
  text-decoration: none;
  transition: all 250ms;
  border: 0;
  font-size: 36px;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.button-33:hover {
  box-shadow: rgba(219, 205, 5, 0.35) 0 -25px 18px -14px inset,
    rgba(219, 205, 5, 0.25) 0 1px 2px, rgba(219, 205, 5, 0.25) 0 2px 4px,
    rgba(219, 205, 5, 0.25) 0 4px 8px, rgba(219, 205, 5, 0.25) 0 8px 16px,
    rgba(219, 205, 5, 0.25) 0 16px 32px;
  transform: scale(1.05) rotate(-1deg);
}
